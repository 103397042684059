<template>
  <div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="text-primary">Create Vendor</h3>
        <hr />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name">Name<span class="text-danger">*</span> :</label>
              <input
                type="tetx"
                class="form-control"
                id="name"
                placeholder="type vendor name.."
                v-model="name"
              />
            </div>

            <div class="form-group">
              <label for="vendor_logo">Vendor Image :</label>
              <input
                type="file"
                name="file"
                ref="file"
                class="form-control-file"
                id="vendor_image"
                @change="onChange"
              />
            </div>
            <div v-if="previewImage">
              <div class="form-group">
                <img
                  class="rounded shadow"
                  style="width: 30%"
                  :src="previewImage"
                  alt="Vendor Image"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="organization"
                >Organization<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="organization"
                label="name"
                track-by="name"
                placeholder="type to find organization"
                open-direction="bottom"
                :options="items_organization"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="asyncFindOrg"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="form-group">
              <label for="is_external">Vendor Type :</label>
              <b-form-select
                class="form-control"
                id="is_external"
                v-model="isExternal"
                :options="optionVendorType"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="is_vendorSource">Vendor Source :</label>
              <b-form-select
                class="form-control"
                id="is_vendorSource"
                v-model="vendorSource"
                :options="optionsVendorSource"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="status">Status :</label>
              <b-form-select
                class="form-control"
                id="status"
                v-model="status"
                :options="options"
              >
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="vendor_id">Vendor ID From AX :</label>
              <input
                type="text"
                class="form-control"
                id="vendor_id"
                placeholder="type vendor ID from AX.."
                v-model="vendorIdAx"
              />
            </div>
            <div class="form-group">
              <label for="description">Description :</label>
              <textarea
                class="form-control"
                id="description"
                rows="4"
                placeholder="type description.."
                v-model="description"
              ></textarea>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isActive"
              />
              <label class="form-check-label" for="is_active">Is Active</label>
            </div> 
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isContentBooks"
                v-model="isContentBooks"
              />
              <label class="form-check-label" for="isContentBooks">Is Content Books</label>
            </div>  
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isContentMagazines"
                v-model="isContentMagazines"
              />
              <label class="form-check-label" for="isContentMagazines">Is Content Magazines</label>
            </div>  
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="isContentNewspapers"
                v-model="isContentNewspapers"
              />
              <label class="form-check-label" for="isContentNewspapers">Is Content Newspapers</label>
            </div>  
            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="createVendor()"
            >
              <i class="fa fa-plus"></i>
              Create Vendor
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import API from '../../plugins/http';
export default {
  name: 'CreateVendor',
  data() {
    return {
      name: '',
      vendorIdAx: '',
      description: '',
      organization: [],
      isLoading: false,
      items_organization: [],
      isActive: true,
      isContentBooks:false,
      isContentMagazines:false,
      isContentNewspapers:false,
      isExternal:false,
      isLoadingSearch: false,
      status: '1',
      vendorSource:'Gramedia Digital',
      file: null,
      previewImage: null,
      options: [
        { value: '1', text: 'New' },
        {
          value: '2',
          text: 'Waiting for Review',
        },
        { value: '3', text: 'in Review' },
        { value: '4', text: 'Rejected' },
        { value: '5', text: 'Approved' },
        { value: '6', text: 'Clear' },
        { value: '7', text: 'Banned' },
      ],
      optionsVendorSource: [
      { value: 'Gramedia Digital', text: 'Gramedia Digital' },
        {
          value: 'SmartLib Client',
          text: 'SmartLib Client',
        },
      ],
      optionVendorType : [
        {value:false, text: 'Internal'},
        {value:true, text: 'External'}
      ]
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Vendor | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('organization', ['searchOrganization']),
    asyncFindOrg(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          search: keyword,
        };
        this.searchOrganization(payload)
          .then((resp) => {
            this.items_organization = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    async createVendor() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('name', this.name);
      formData.append('organization', this.organization.id);
      formData.append('status', this.status);
      formData.append('vendorIdAx', this.vendorIdAx);
      formData.append('description', this.description);
      formData.append('isContentBooks', this.isContentBooks);
      formData.append('isContentMagazines', this.isContentMagazines)
      formData.append('isContentNewspapers', this.isContentNewspapers)
      formData.append('isActive', this.isActive);
      formData.append('isExternal', this.isExternal);
      formData.append('vendorSource',this.vendorSource)
      try {
        await API.post('/vendor/create', formData)
          .then((res) => {
            this.isLoading = false;
            if (res.status === 201) {
              this.$router.push('/vendor');
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Vendor Name Has Been Successfully Created',
                timer: 15000,
                text: this.message,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title:
                error.response.status === 422
                  ? error.response.data.message
                  : error.response.data.data.message,
              timer: 10000,
              text: this.message,
            });
          });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: 'error',
          toast: true,
          position: 'top-end',
          title: 'Failed To Create New Vendor Name',
          timer: 5000,
          text: this.message,
        });
      }
    },

    onChange(e) {
      this.file = e.target.files[0];
      let input = this.$refs.file;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit('input', file[0]);
      }
    },
  },
};
</script>
